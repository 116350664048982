<template>
  <button
    type="button"
    class="v-btn v-btn--icon v-theme--BLUE_THEME text-primary v-btn--density-default v-btn--size-default v-btn--variant-text"
    aria-haspopup="menu"
    aria-expanded="false"
    aria-owns="v-menu-81"
    v-if="currentLanguage === 'en'"
  >
    <span class="v-btn__overlay"></span><span class="v-btn__underlay"></span
    ><span class="v-btn__content" data-no-activator=""
      ><div
        class="v-avatar v-avatar--density-default v-avatar--variant-flat"
        style="width: 22px; height: 22px"
      >
        <img
          src="@/assets/icons/icon-flag-en.svg"
          alt="en"
          width="24"
          height="24"
          class="obj-cover"
          @click="openLanguageModal"
        /><span class="v-avatar__underlay"></span></div
    ></span>
  </button>
  <button
    type="button"
    class="v-btn v-btn--icon v-theme--BLUE_THEME text-primary v-btn--density-default v-btn--size-default v-btn--variant-text"
    aria-haspopup="menu"
    aria-expanded="false"
    aria-owns="v-menu-81"
    v-else
  >
    <span class="v-btn__overlay"></span><span class="v-btn__underlay"></span
    ><span class="v-btn__content" data-no-activator=""
      ><div
        class="v-avatar v-avatar--density-default v-avatar--variant-flat"
        style="width: 22px; height: 22px"
      >
        <img
          src="@/assets/icons/icon-flag-ua.svg"
          alt="en"
          width="24"
          height="24"
          class="obj-cover"
          @click="openLanguageModal"
        /><span class="v-avatar__underlay"></span></div
    ></span>
  </button>
  <base-modal   
    v-model="showLanguageModal"
    :input-event="languageModalEvent"
    content-width="270px"
    content-height="300px"
    position="left"
    offsetLeft="20"
  >    
    <div
      class="v-list v-theme--BLUE_THEME v-list--density-default v-list--one-line theme-list"
      role="listbox"
    >
      <div
        class="v-list-item v-list-item--active v-list-item--link v-theme--BLUE_THEME text-primary v-list-item--density-default v-list-item--one-line v-list-item--variant-text d-flex align-center"
        tabindex="0"
        style="min-height: 45px"
        @click.prevent="setLang('en')"
      >
        <span class="v-list-item__overlay"></span
        ><span class="v-list-item__underlay"></span>
        <div class="v-list-item__prepend">
          <!----><!---->
          <div
            class="v-avatar v-avatar--density-default v-avatar--variant-flat"
            style="width: 22px; height: 22px"
          >
            <img
              src="@/assets/icons/icon-flag-en.svg"
              alt="@/assets/icons/icon-flag-en.svg"
              width="22"
              height="22"
              class="obj-cover"
            /><!----><span class="v-avatar__underlay"></span>
          </div>
        </div>
        <div class="v-list-item__content">
          <!----><!---->
          <div class="v-list-item-title text-subtitle-1 font-weight-regular">
            English <span class="text-disabled text-subtitle-1 pl-2">(UK)</span>
          </div>
        </div>
        <!---->
      </div>
      <div
        class="v-list-item v-list-item--link v-theme--BLUE_THEME v-list-item--density-default v-list-item--one-line v-list-item--variant-text d-flex align-center"
        tabindex="0"
        style="min-height: 45px"
        @click.prevent="setLang('ua')"
      >
        <span class="v-list-item__overlay"></span
        ><span class="v-list-item__underlay"></span>
        <div class="v-list-item__prepend">
          <!----><!---->
          <div
            class="v-avatar v-avatar--density-default v-avatar--variant-flat"
            style="width: 22px; height: 22px"
          >
            <img
              src="@/assets/icons/icon-flag-ua.svg"
              alt="@/assets/icons/icon-flag-ua.svg"
              width="22"
              height="22"
              class="obj-cover"
            /><!----><span class="v-avatar__underlay"></span>
          </div>
        </div>
        <div class="v-list-item__content">
          <!----><!---->
          <div class="v-list-item-title text-subtitle-1 font-weight-regular">
            Ukraine
            <span class="text-disabled text-subtitle-1 pl-2">(UA)</span>
          </div>
        </div>
        <!---->
      </div>  
    </div>
  </base-modal>
</template>
<script>
import {mapActions} from "pinia";
import {useMenuStore} from "@/stores/menuStore";
import BaseModal from "@/components/BaseModal.vue";

export default {
  name: "languageSwitcher",
  props: {},
  components: { BaseModal },
  data() {
    return {
      showLanguageModal: false,
      languageModalEvent: null,
      languages:[
        {
          id:"",
          icon:""
        }
      ]
    };
  },
  computed:{
    currentLanguage(){
      return this.$i18n.locale;
    }
  },
  methods: {
    ...mapActions(useMenuStore, ["getUserMenu"]),
    openLanguageModal(event) {
      this.languageModalEvent = event;
      this.showLanguageModal = true;     
    },
    closeLanguageModal(){
       this.showLanguageModal = false;
    },
    async setLang(lang) {
       this.$i18n.locale = lang;
        document.querySelector("html").setAttribute("lang", lang);
        localStorage.setItem("lang", lang);
        this.getUserMenu();
        this.closeLanguageModal();

    },
  },
  mounted() {},
};
</script>
<style lang="scss"></style>
