<template>
  <a
      class="navItemLink rounded-md cursor-pointer
      navItemLink rounded-md cursor-pointer
      mobile-menu
      v-list-item v-list-item--link v-theme--ORANGE_THEME v-list-item--density-default v-list-item--one-line v-list-item--rounded v-list-item--variant-text mb-1 leftPadding"
      tabindex="-2"
      target=""
      style="min-height: 45px;"
      :href="item.href"
      @click.prevent="goToUrl(item)"
      @click="openSubMenu()">
    <span class="v-list-item__overlay"></span>
    <span class="v-list-item__underlay"></span>
    <div class="v-list-item__prepend">
      <i class="navIcon" v-if="item.icon">
        <Icon :icon="item.icon" class="icon icon-tabler" height="18"/>
      </i>
    </div>

    <div class="v-list-item__content mobile-menu" data-no-activator="">
      <span class="mr-auto">{{ item.label }}</span>
    </div>
    <i class="ddIcon ml-10 align-center" v-if="hasSubmenu(item)"
    >
      <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-chevron-down"
          width="15px"
          height="15px"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <polyline points="6 9 12 15 18 9"></polyline>
      </svg>
    </i>
  </a>
  <div v-if="hasSubmenu(item)"
       class="v-list-group__items mobile-menu"
       :class="{ 'show-mobile-submenu': showMobileSubMenu }"
       role="group"
       aria-labelledby="v-list-group--id-Symbol(29)">
    <header-menu-item
        v-for="subItem in item.items"
        :key="subItem.id"
        :item="subItem"
        @click-outside="closeMobileMenu"></header-menu-item>
  </div>
</template>
<script>

import {sendRequest} from "@/helpers";
import {Icon} from "@iconify/vue";

export default {
  name: "HeaderMenuItem",
  emits: ["click-outside"],
  props: {
    item: {
      type: Object,
    },
    actionButton: {
      type: String,
    },
  },
  components: {
    Icon
  },
  data() {
    return {
      showDialog: false,
      showMobileSubMenu: false,
      dialogType: null,
      requestResalt: null,
      status: null
    };
  },
  methods: {
    hasSubmenu(item) {
      return Object.prototype.hasOwnProperty.call(item, "items") ? true : false;
    },
    openSubMenu() {
      if (!this.showMobileSubMenu) {
        this.showMobileSubMenu = true;
      } else {
        this.showMobileSubMenu = false;
      }
    },
    goToUrl(item) {
      let itemId = item?.id;
      switch (item.type) {
        case "entity_list":
          this.$router.push({path: item?.href, replace: true});
          this.$emit("click-outside");
          break;
        case "internal_link":
          this.$router.push({path: item?.href, replace: true});
          this.$emit("click-outside");
          break;
        case "external_link":
          this.$router.push({name: "external", params: {id: itemId}, replace: true});
          this.$emit("click-outside");
          break;
        case "api_method":
          this.sendRequest(item);
          this.$emit("click-outside");
          break;
        default:
          break;
      }

    },
    closeMobileMenu() {
      this.$emit("click-outside");
    },
    async sendRequest(item) {
      let res = await sendRequest(item.href, 'get');
      if (res) {
        this.requestResalt = "Ok";
        this.status = 'ok';
        this.dialogType = "success"
        this.showDialog = true;
      } else {
        this.requestResalt = "Error";
        this.dialogType = "error"
        this.status = 'not ok';
        this.showDialog = true;
      }
    },
  },
  mounted() {
  },
  unmounted() {
  },
};
</script>
<style scoped>
.v-list-group__items {
  padding-left: 25px;
  display: none;
}
.show-mobile-submenu {
  display: block;
}
.mobile-menu {
  user-select: none;
}
</style>
