<template>
  <div class="v-row">
    <div class="v-col-md-12 v-col-12">
      <base-card>
        <template v-slot:header>
          <div class="d-flex justify-content-between w-100">
            <h2>{{ entityFields?.title }}</h2>
            <base-button
                :variant="'pure'"
                @click="addEntityInstance"
                className="v-btn v-btn--flat v-theme--BLUE_THEME bg-primary v-btn--density-default v-btn--size-default v-btn--variant-elevated ml-auto"
                v-if="entityPermission?.create"
            >
              {{ $t("buttons.addNew") }}
              <span class="ml-1"><b>{{ entityFields?.title }} </b></span>
            </base-button>
          </div>
        </template>

        <div v-if="rowData.length == 0" class="d-flex h-100 w-100 flex-column align-items-center justify-content-center" style="min-height: 20vh;">
          <h2 v-if="loading > 0">{{ $t('lables.loading') + '...' }}</h2>
          <h2 v-else>{{$t('lables.noData')}}</h2>
        </div>
        <base-table
            :key="tableKey"
            v-if="entityPermission && rowData.length > 0"
            ref="listTable"
            :row="rowData"
            :countAllRows="countAllRows"
            :startRowsLoaded="startRowsLoaded"
            :columnDefs="column"
            :settings="settings"
            :loadNextPage="loadNextPage"
            :entity="entity"
            :countRowsLoaded="countRowsLoaded"
            :entityPermission="entityPermission"
            @showRowBtnClick="onShowRowBtnClick"
            @editRowBtnClick="onEditRowBtnClick"
            @deleteRowBtnClick="onDeleteRowBtnClick"
        ></base-table>
      </base-card>
    </div>
  </div>
  <base-modal v-model="showInstanceDetail"
              :showOwerlay="true"
              :currentPath="currentPath"
              :previsionPath="previsionPath">
    <instance-card
        :entity="entityFields.model"
        :item="instanceObject"
        :schema="entityFields"
        :cardType="instanceCardType"
        @inctanse-data-update="onInctanseDataUpdate"
        @inctanse-create="onInctanseCreate"
        @close-modal="closeInstanceCardModal"
    >
    </instance-card>
  </base-modal>

  <spinner v-if="loading > 0"></spinner>
</template>
<script>
import BaseTable from "@/components/BaseTable.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseModal from "@/components/BaseModal.vue";
import EntityApi from "@/api/entityApi";
import InstanceCard from "@/pages/entityPages/InstanceCard.vue";
import Spinner from "@/components/Spinner.vue";

import { hasProperty } from "@/helpers";
import { uuid } from "vue-uuid";
import { computed } from 'vue';

export default {
  name: "EntityList",
  props: {},
  inject: [],
  components: {BaseTable, BaseCard, BaseButton, BaseModal, InstanceCard, Spinner},
  data() {
    return {
      spinnerShow: true,
      entityFields: null,
      instanceObject: null,
      instanceCardType: "edit",
      column: null,
      rowData: [],
      settings: {},
      showInstanceDetail: false,
      refreshRow: null,
      loadNextPage:true,
      entity: '',
      countRowsLoaded:30,
      startRowsLoaded:100,
      countAllRows:0,
      currentPath: null,
      previsionPath: null,
      entitys: null,
      tableKey:1,

      loading: 0,
      
      arrayNames: ['date', 'name','conveniq'],
      width_spec: 140,
    };
  },
  watch: {
    $route(to) {
      this.ititEntityPage();
      this.entitys = to.params.entitys
      this.previsionPath = to.fullPath;
      console.log(to);
    },
  },
  computed: {
    entityPermission(){
      if (this.entityFields?.permissions){
        return this.entityFields.permissions
      }
      else return null
    }
    // processedRefreshRow() {
    //   const mydata = this.refreshRow;
    //   return mydata;
    // }
  },
  methods: {
    async ititEntityPage() {
      this.column = null;
      if (this.$route?.params?.entitys) {
        await this.getEntityFields(this.$route?.params?.entitys);
        await this.getEntityItems(this.$route?.params?.entitys);
      }
    },
    addEntityInstance() {
      console.log("addEntityInstance");
      this.instanceCardType = "create";
      this.instanceObject = {};
      this.showInstanceDetail = true;
    },
    onInctanseCreate(data) {
      this.rowData?.push(data);
      // this.refreshRow = data.id;
      this.$refs.listTable.addNewRow(data);
      this.instanceObject = {};
      this.showInstanceDetail = false;
    },

    async getDetailRow(entity, id){
      if(!entity || !id){ 
        console.log('getDetailRow, no entity or id', entity, id);
        return
      }
      
      this.loading++;
      EntityApi.getDataRow(entity, id).then(res => {
        this.loading--;
        this.instanceObject = res;
        this.showInstanceDetail = true;
      });
    },
    onShowRowBtnClick(row) {
      console.log("onShowRowBtnClick", row);
      this.currentPath = `/details/${this.entitys}/${row.id}`
      this.instanceCardType = "show";

      //this.getDetailRow(this.entitys, row.id)
      this.instanceObject = row; 
      this.showInstanceDetail = true;
    },
    onEditRowBtnClick(row) {
      // console.log("onEditRowBtnClick", row);
      this.instanceCardType = "edit";

      //this.getDetailRow(this.entitys, row.id)
      this.instanceObject = {...row};
      this.showInstanceDetail = true;
    },
    onDeleteRowBtnClick(row) {
      this.rowData = this.rowData.filter((el) => el.id !== row.id);
      if(this.entityFields?.custom_endpoints && hasProperty(this.entityFields.custom_endpoints, 'delete') ){
        let customDeleteEndpoint = this.entityFields.custom_endpoints.delete;
        EntityApi.sendCustomRequest(customDeleteEndpoint?.url, customDeleteEndpoint?.method, row);
      }
      else {
        EntityApi.deleteEntityInstance(this.$route?.params?.entitys, row?.id);
      } 
    },
    closeInstanceCardModal(){
      const modalId = this.entitys;
      window.history.pushState({ modalId }, null, this.previsionPath);
      this.showInstanceDetail = false;
      this.instanceObject = {};
    },
    onInctanseDataUpdate(data) {
      // console.log("onInctanseDataUpdate");
      let obg = this.rowData?.find((el) => el.id === data.id);
      for (const key in data) {
        if (key !== "id") {
          obg[key] = data[key];
        }
      }
      this.$refs.listTable.refreshRowData(data);
      this.instanceObject = null;
      this.showInstanceDetail = false;
    },
    async getEntityFields(entity) {
      this.loading++;
      EntityApi.getEntityFields(entity).then(res => {
        this.loading--;
        this.entityFields = res;
        this.column = this.generateColumn(this.entityFields);
      });
      
    },
    async getEntityItems(entity) {
      this.loading++;
      //this.rowData = await EntityApi.getEntityItems(entity);
      EntityApi.getEntityItemsLimit(entity, 0, this.startRowsLoaded, null, true).then(res => {
        this.loading--;
        if(!res && !res.rows) return
        if(hasProperty(res.rows[0], 'id') ){
          this.rowData = res.rows;
        }
        else {
          this.rowData = res.rows.map(el => {
            return {...el, ...{id: uuid.v4()}}
          })
        }
        
        let lenLoaded = res.rows.length;
        this.startRowsLoaded = lenLoaded;
        this.countAllRows = res.lastRow;
        this.countRowsLoaded = lenLoaded;
        this.entity = entity;
        this.tableKey = this.tableKey+1;
      });
      
    },
    isFileField(field) {
      return this.entityFields?.file_fields.includes(field) ? true : false;
    },

    
    dtFormat(dt){

      function convertDateForIos(date) {
        if(typeof date == 'string'){
          let arr = date.split(/[- :]/);
          let dt2 = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
          return dt2;
        } else {
          return date
        }
      }

      if((dt != '') && (dt != null)){
        let d = null;
        if( typeof dt == 'string'){

          if(dt.indexOf('T') > -1){
            let arr = (dt).split('T');
            let dataT = arr[0] + ' ' + arr[1];
            d = convertDateForIos(dataT);
          } else {
            d = convertDateForIos(dt);
          }

          let dtn = +convertDateForIos(d)
          if( dtn < 0){
            return null
          }
        } else if(typeof dt == 'number'){
          d = new Date(dt);
        }else {
          d = new Date(dt);
        }

        let obj = {
          year: d.getFullYear(),
          month: d.getMonth()+1,
          day: d.getDate(),
          hours: d.getHours(),
          min: d.getMinutes(),
          sec: d.getSeconds(),
          int: new Date(d).getTime()
        }
        
        return obj;
      } else {
        return dt;
      }

    },
    numberComparator: (valueA, valueB) => {
      if (valueA == valueB) return 0;
        return (valueA > valueB) ? 1 : -1;
    },
    dateComparator: (valueA, valueB) => {
      if (!valueA && !valueB) {         
        return 0;
      }
      if (!valueA) {
        return -1;
      }
      if (!valueB) {
        return 1;
      }         
      let strToUnix = (str) =>{
        const [dateRelated, timeRelated] = str.split(' ');          
        const [day, month, year] = dateRelated.split('.');         
        const [hours, minutes, seconds] = timeRelated.split(':');
        return new Date(+year, month - 1, +day, +hours, +minutes, +seconds).getTime(); 
      } 
      let cellValueA = strToUnix(valueA);
      let cellValueB = strToUnix(valueB);        
      if (cellValueA === null && cellValueB === null) {
        return 0;
      }
      if (cellValueA === null) {
        return -1;
      }
      if (cellValueB === null) {
        return 1;
      }       
      return cellValueA - cellValueB;
    },
    generateColumnItem(itemObg, item) {
      //console.log('generateColumnItem',itemObg, item);
      let newItem = {
        field: item,
      };
      if(this.arrayNames.includes(item)){
        newItem.minWidth = this.width_spec;
      }
      if (hasProperty(itemObg, "title")) {
        newItem.headerName = itemObg.title;
      }
      if (hasProperty(itemObg, "$ref") ) {
        if (hasProperty(itemObg, "type") && itemObg.type === 'enum'){
          return newItem;
        }
        else {
          newItem.cellRenderer = "objectRenderer";
          let field = 'name';
          if(itemObg && itemObg.title_field_name){
            field = itemObg.title_field_name;
            newItem.customTitleField = itemObg.title_field_name;
          }
          newItem.valueGetter  = 'data.' + item + '.' + field;
          
        }
        // newItem.keyCreator = params => params.value.name;
      }
      if (this.isFileField(item)){
        newItem.cellRenderer = "fileRenderer";
      }
      if(hasProperty(itemObg, "format")){
        switch (itemObg.format) {
          case "date-time":
            newItem.cellRenderer = "dateRenderer";
            newItem.editable = false;
            newItem.comparator = this.dateComparator;
            newItem.enablePivot = true;
            newItem.enableRowGroup = true;
            newItem.filter = "agDateColumnFilter";
            newItem.pivot = false;
            newItem.resizable = true;
            newItem.rowGroup = false;
            newItem.sortable = true;
            newItem.filterParams = {
              browserDatePicker: true,
              buttons: ['reset'],
              comparator:  (filterLocalDateAtMidnight, cellValue) => {
                if (cellValue == null) return -1;
                let cellDate = this.dtFormat(cellValue);
                let filterVal = this.dtFormat(filterLocalDateAtMidnight);
                if((cellDate.day == filterVal.day) && (cellDate.month == filterVal.month) && (cellDate.year == filterVal.year)) {
                  return 0;
                }
                if (cellDate.int <= filterVal.int) {
                  return -1;
                }
                if (cellDate.int >= filterVal.int) {
                  return 1;
                }
              },
            }
            break;
          default:
            break;
        }
      }
      if(hasProperty(itemObg, "type")){
        switch (itemObg.type) {
          case "boolean":
            newItem.cellRenderer = "boolRenderer";
            break;
          case "number":
            newItem.sortable = true;
            newItem.filter = "agNumberColumnFilter";
            newItem.pivot = false;
            newItem.enablePivot = true;
            newItem.enableRowGroup = true;
            newItem.rowGroup = false;
            newItem.resizable = true;
            newItem.editable = false;
            newItem.comparator = this.numberComparator;
            break;
          default:
            break;
        }
      }
      return newItem;
    },
    generateColumn(schema) {
      let column = [];
      let actionColl = {
        cellRenderer: "rowMenuCellRenderer",
        checkboxSelection: false,
        headerCheckboxSelection: false,
        headerName: "",
        /*maxWidth: 65,*/
        minWidth: 65,
        /*width: 65,*/
        pinned: false,
        sortable: false,
        suppressMenu: false,
        filter:false,
        editable:false
      };
      column.push(actionColl);
      if (schema?.list_columns && Array.isArray(schema.list_columns)) {
        schema.list_columns.forEach((el) => {
          if (el !== "id") {
            let columnItem = this.generateColumnItem(
                schema?.properties[el],
                el
            );
            if (columnItem) {
              column.push(columnItem);
            }
          }
        });
      }
      return column;
    },

  },
  provide() {
    return {
      refreshRow: computed(() => this.refreshRow)
    }

  },
  created() {
    this.ititEntityPage();
  },
  mounted() {
    this.entitys = this.$route?.params.entitys
    this.previsionPath = this.$route?.fullPath;
  },

};
</script>
<style scoped>
.df-preview-wrapper{
  box-shadow:none !important;
}
</style>
