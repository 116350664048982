import {defineStore} from "pinia";
import EntityApi from "@/api/dashboardApi";
import {getMonthName} from '../helpers/index'

export const useDashboardStore = defineStore("dashboard", {
    state: () => ({
        dataDashboard: [],
        dataReplanishment: [],
        dataWriteOff: [],
        dataPlanogramma: [],
        dataSalesMonth: [],
        columsSalesMonth: [],
        dataSales: [],
        columsSales: [],
        dataRemainingGood: [],
        columsRemainingGood: [],
        columsItemSales:[],
        selectedDevice: [],
        selectedItem: [],
        settingDashboard: {
            "startData": undefined,
            "endData": undefined,
            "conveniq": [],
            "category": []
        },
        statusBarReportTable:{},
        datePicker:new Date()
    }),
    getters: {},
    actions: {
        async getSettingsDashboard() {
            let res = await EntityApi.getSettings();
            return res;
        },
        async getMainDashboard(params) {
            let res = await EntityApi.getMainDashboard(params);
            if (res) {
                this.dataDashboard = res;
                //this._selectedDevice = [];
                //this._selectedItem = [];
            } else return null;
        },
        async postMainDashboard(entity, params) {
            let res = await EntityApi.postMainDashboard(entity, params);
            if (res) {
                this.dataDashboard = res;
            } else return null;
        },
        async getPlanogramma(params) {
            let res = await EntityApi.getPlanogrammaData(params);
            if (res) {
                this.dataPlanogramma = res;
            } else return null;
        },
        async postPlanogramma(params) {
            let res = await EntityApi.postPlanogrammaData(params);
            if (res) {
                this.dataPlanogramma = res;
            } else return null;
        },
        async setDataForReportSalesMonth(data) {
            const transformedData = data.reduce((acc, obj) => {
                const monthNum = parseInt(obj.month);
                const monthName = getMonthName(monthNum);

                if (!acc[obj.nameDevice]) {
                    acc[obj.nameDevice] = {};
                }

                if (!acc[obj.nameDevice][monthName]) {
                    acc[obj.nameDevice][monthName] = {
                        sum: 0,
                        ids: [],
                    };
                }

                acc[obj.nameDevice][monthName].sum += obj.summa;
                acc[obj.nameDevice][monthName].ids.push(obj.id);

                return acc;
            }, {});

            const result = Object.keys(transformedData).map((nameDevice, index) => {
                const deviceData = transformedData[nameDevice];
                const deviceResult = {
                    id: index + 1,
                    nameDevice,
                };

                Object.keys(deviceData).forEach((monthName) => {
                    deviceResult[monthName] = deviceData[monthName].sum + ' грн.';
                });

                return deviceResult;
            });

            const columns = Array.from(
                new Set(result.flatMap((obj) => Object.keys(obj)))
            );

            this.columsSalesMonth = columns.map((column) => ({field: column, width: 200}));
            this.dataSalesMonth = result;
        },
        async setDataForReportSales(data, t_month) {
            this.columsSales = [];
            this.dataSales = [];
            // Selection of unique values of the "name" field
            const uniqueNames = [...new Set(data.map(item => item.name))];

            // Create a new array of structures with the field "field" and a value that is the unique value of the field "name"
            this.columsSales = uniqueNames.map(name => (
                {
                    field: name,
                    minWidth: 200,
                }
            ));

            // Adding fields "field:'Conveniq'" and "field:'Month'" to the beginning of the array
            this.columsSales.unshift(
                {
                        field: 'Conveniq',
                        minWidth: 200,
                     },
                     {
                        field: 'Month',
                        minWidth: 150,
                        headerName: t_month
                     }
            );

            const rowDataObj = {};
            data.forEach(element => {
                const {idDevice, month, name, summa} = element;
                const key = `${idDevice}-${month}`;
                if (!rowDataObj[key]) {
                    rowDataObj[key] = {
                        Conveniq: element.nameDevice,
                        Month: getMonthName(month),
                        summa: 0
                    };
                }
                rowDataObj[key][name] = (rowDataObj[key][name] || 0) + summa;
                rowDataObj[key].summa +=summa;
            });

            this.dataSales = Object.values(rowDataObj).map((row, index) => ({
                id: index + 1, // Adding the "id" field with a unique identifier
                ...row,
            }));

            this.dataSales.forEach((item) => {
                for (const [key, value] of Object.entries(item)) {
                    if (key !== 'id' && typeof value === 'number' ) {
                        item[key] = value + ' грн.';
                    }
                }
            })

        },
        async getDataReportItemSales(data) {

            const currentDate = new Date();
            const day = String(currentDate.getDate()).padStart(2, '0'); // Отримуємо день та додаємо ведучий 0, якщо число менше 10
            const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Отримуємо місяць (0-11), додаємо 1 та ведучий 0, якщо число менше 10
            const year = currentDate.getFullYear(); // Отримуємо рік
            const currentFormattedDate = `${day}.${month}.${year}`;

            let filtredDate = data.filter(item => {
                const itemDate = item.date.substring(0, 10);
                return currentFormattedDate === itemDate;
            });

            const colums = [
                {
                    field: 'id',
                    headerName: '№',
                    width:50,
                    hide:true,
                    filter: false,
                },
                {
                    field: 'item',
                    headerName: 'Назва продукта',
                    width:200,
                    filter: false,
                },
                {
                    field: 'quantity',
                    headerName: 'Кількість',
                    width:100,
                    filter: false,
                },
                {
                    field: 'amount',
                    headerName: 'Сума',
                    width:100,
                    filter: false,
                },
            ];

            // Create an empty object to hold the aggregated data
            const aggregatedData = {};

            // Loop through the data and aggregate the values based on item_name
            filtredDate.forEach((item) => {
                if (!aggregatedData[item.item_name]) {
                    aggregatedData[item.item_name] = {
                        item: item.item_name,
                        quantity: 0,
                        amount: 0,
                    };
                }
                aggregatedData[item.item_name].quantity += item.quantity;
                aggregatedData[item.item_name].amount += item.summa;
            });

            // Convert the object values to an array of structures
            const rows = Object.values(aggregatedData);

            let statusBar = {
                statusPanels: [
                    { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                    { statusPanel: 'agTotalRowCountComponent', align: 'center' },
                    { statusPanel: 'agFilteredRowCountComponent' },
                    { statusPanel: 'agSelectedRowCountComponent' },
                    {
                        statusPanel: 'agAggregationComponent',
                        statusPanelParams: { aggFuncs: ['count', 'sum', 'min', 'max', 'avg'] },
                    }
                ],
            };

            return  {
                colums: colums,
                rows: rows,
                statusBar:statusBar
            }

        },
        async setDataForReportRemainingGood(conveniqId) {
            this.columsRemainingGood = [];
            this.dataRemainingGood = [];

            const foundObject = this.dataPlanogramma.find(item => item.conveniq_id === conveniqId);
            const transformedData = foundObject ? foundObject.products : [];

            this.columsRemainingGood = [
                {
                    field: 'shelf',
                    headerName: 'Shelf',
                    width:250,
                    hide:true
                },
                {
                    field: 'item',
                    width: 250,
                    headerName: 'Item',
                },
                {
                    field: 'min_quantity',
                    headerName: 'Min quantity for load',
                },
                {
                    field: 'max_quantity',
                    headerName: 'Max quantity for load',
                },
                {
                    field: 'current_quantity',
                    headerName: 'Current quantity load',
                },
                {
                    field: 'min_amount',
                    headerName: 'Min amount for load',
                },
                {
                    field: 'max_amount',
                    headerName: 'Max amount for load',
                },
                {
                    field: 'current_amount',
                    headerName: 'Current amount load',
                },
                {
                    field: 'load_quantity',
                    headerName: 'Need load (quantity)',
                },
                {
                    field: 'load',
                    headerName: 'Need load (amount)',
                }
            ]
            const rowDataObj = transformedData.map(item => {
                return {
                    item: item.item,
                    shelf: item.shelf,
                    min_quantity: item.min_quantity,
                    max_quantity: item.max_quantity,
                    current_quantity: item.current_quantity,
                    min_amount: item.min_amount,
                    max_amount: item.max_amount,
                    current_amount: item.current_amount,
                    load: item.max_amount - item.current_amount,
                    load_quantity: item.max_quantity - item.current_quantity
                };
            });
            this.dataRemainingGood = Object.values(rowDataObj).map((row, index) => ({
                id: index + 1, // Adding the "id" field with a unique identifier
                ...row,
            }));
            this.statusBarReportTable = {
                statusPanels: [
                    { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                    { statusPanel: 'agTotalRowCountComponent', align: 'center' },
                    { statusPanel: 'agFilteredRowCountComponent' },
                    { statusPanel: 'agSelectedRowCountComponent' },
                    {
                        statusPanel: 'agAggregationComponent',
                        statusPanelParams: { aggFuncs: ['count', 'sum', 'min', 'max', 'avg'] },
                    }
                ],
            };

        },
        async setDataForReportRemainingGoodFilters() {
            this.columsRemainingGood = [];
            this.dataRemainingGood = [];

            console.log("this.dataPlanogramma", this.dataPlanogramma);
            let transformedData = [];
            for (const item of this.dataPlanogramma) {
                if (item.products) {
                    for (const product of item.products) {
                        const newProduct = { ...product, conveniq: item.conveniq };
                        transformedData.push(newProduct);
                    }
                }
            }

            this.columsRemainingGood = [
                {
                    field: 'conveniq',
                    width: 120,
                    headerName: 'Conveniq',
                },
                {
                    field: 'shelf',
                    width: 150,
                    headerName: 'Shelf',
                },
                {
                    field: 'item',
                    width: 250,
                    headerName: 'Item',
                },
                {
                    field: 'min_quantity',
                    headerName: 'Min quantity for load',
                    aggFunc: 'sum',
                },
                {
                    field: 'max_quantity',
                    headerName: 'Max quantity for load',
                    aggFunc: 'sum',
                },
                {
                    field: 'current_quantity',
                    headerName: 'Current quantity load',
                    aggFunc: 'sum',
                },
                {
                    field: 'min_amount',
                    headerName: 'Min amount for load',
                    aggFunc: 'sum',
                },
                {
                    field: 'max_amount',
                    headerName: 'Max amount for load',
                    aggFunc: 'sum',
                },
                {
                    field: 'current_amount',
                    headerName: 'Current amount load',
                    aggFunc: 'sum',
                },
                {
                    field: 'load_quantity',
                    headerName: 'Need load (quantity)',
                    aggFunc: 'sum',
                },
                {
                    field: 'load',
                    headerName: 'Need load (amount)',
                    aggFunc: 'sum',
                }
            ]
            const rowDataObj = transformedData.map(item => {
                return {
                    conveniq: item.conveniq,
                    item: item.item,
                    shelf: item.shelf,
                    min_quantity: item.min_quantity,
                    max_quantity: item.max_quantity,
                    current_quantity: item.current_quantity,
                    min_amount: item.min_amount,
                    max_amount: item.max_amount,
                    current_amount: item.current_amount,
                    load: item.max_amount - item.current_amount,
                    load_quantity: item.max_quantity - item.current_quantity
                };
            });
            this.dataRemainingGood = Object.values(rowDataObj).map((row, index) => ({
                id: index + 1, // Adding the "id" field with a unique identifier
                ...row,
            }));

            this.statusBarReportTable = {
                statusPanels: [
                    { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                    { statusPanel: 'agTotalRowCountComponent', align: 'center' },
                    { statusPanel: 'agFilteredRowCountComponent' },
                    { statusPanel: 'agSelectedRowCountComponent' },
                    {
                        statusPanel: 'agAggregationComponent',
                        statusPanelParams: { aggFuncs: ['count', 'sum', 'min', 'max', 'avg'] },
                    }
                ],
            };

        },
        async setDataForReportSalesWeek(week, data) {
            this.columsRemainingGood = [];
            this.dataRemainingGood = [];

            // Selection of unique values of the "name" field
            const uniqueNames = [...new Set(data.map(item => item.name))];

            // Create a new array of structures with the field "field" and a value that is the unique value of the field "name"
            this.columsRemainingGood = uniqueNames.map(name => ({field: name}));

            // Adding fields "field:'Conveniq'" and "field:'Month'" to the beginning of the array
            this.columsRemainingGood.unshift({field: 'Conveniq'}, {field: 'Week'});

            const filteredData = data.filter(item => item.week == week);

            const rowDataObj = {};
            filteredData.forEach(element => {
                const {idDevice, week, name, summa} = element;
                const key = `${idDevice}-${week}`;
                if (!rowDataObj[key]) {
                    rowDataObj[key] = {
                        Conveniq: element.nameDevice,
                        Week: week + ' тиждень',
                        summa: 0
                    };
                }
                rowDataObj[key][name] = (rowDataObj[key][name] || 0) + summa;
                rowDataObj[key].summa += summa;
            });

            this.dataRemainingGood = Object.values(rowDataObj).map((row, index) => ({
                id: index + 1, // Adding the "id" field with a unique identifier
                ...row,
            }));


        },
        async setDataForReportAverageСheck(data, uniqueNamesItem) {
            let filteredData = data;
            if(uniqueNamesItem && uniqueNamesItem.length > 0){
                filteredData = data.filter(item => uniqueNamesItem.includes(item.name));
            }

            this.statusBarReportTable = {};
            this.columsRemainingGood = [
                {
                    field: 'id',
                    headerName: '№',
                    width:50,
                    hide:true
                },
                {
                    field: 'date',
                    headerName: 'Date',
                },
                {
                    field: 'nameDevice',
                    headerName: 'Name device',
                },
                {
                    field: 'quantity',
                    aggFunc: 'sum',
                    headerName: 'Quantity',
                },
                {
                    field: 'summa',
                    aggFunc: 'sum',
                    headerName: 'Suma',
                }
            ];

            const result = Object.values(filteredData.reduce((acc, obj) => {
                const { quidCheck, quantity, summa } = obj;
                if (acc[quidCheck]) {
                    acc[quidCheck].quantity += quantity;
                    acc[quidCheck].summa += summa;
                } else {
                    acc[quidCheck] = {
                        quidCheck,
                        quantity,
                        summa,
                        date: obj.date,
                        nameDevice: obj.nameDevice
                    };
                }
                return acc;
            }, {}));

            this.dataRemainingGood = result.map((obj, i) => {
                return { ...obj, id: String(i+1) };
            });

            this.statusBarReportTable = {
                statusPanels: [
                    { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                    { statusPanel: 'agTotalRowCountComponent', align: 'center' },
                    { statusPanel: 'agFilteredRowCountComponent' },
                    { statusPanel: 'agSelectedRowCountComponent' },
                    {
                        statusPanel: 'agAggregationComponent',
                        statusPanelParams: { aggFuncs: ['count', 'sum', 'min', 'max', 'avg'] },
                    }
                ],
            };

        },
        async setDataForIndicatorReportSales(data, uniqueNamesItem) {
            let filteredData = data;
            if(uniqueNamesItem && uniqueNamesItem.length > 0){
                filteredData = data.filter(item => uniqueNamesItem.includes(item.name));
            }
            
            this.statusBarReportTable = {};
            this.columsRemainingGood = [
                {
                    field: 'id',
                    headerName: '№',
                    width:50,
                    hide:true
                },
                {
                    field: 'date',
                    headerName: 'Date',
                    width:100,
                },
                {
                    field: 'nameDevice',
                    headerName: 'Name device',
                    width:120,
                },
                {
                    field: 'name',
                    headerName: 'Categories',
                    width:150,
                },
                {
                    field: 'item_name',
                    headerName: 'Item',
                    width:300,
                },
                {
                    field: 'price',
                    headerName: 'Price',
                    width:100,
                    /*valueFormatter: '$'*/
                },
                {
                    field: 'quantity',
                    aggFunc: 'sum',
                    headerName: 'Quantity',
                    width:100,
                },
                {
                    field: 'summa',
                    aggFunc: 'sum',
                    headerName: 'Suma',
                    width:100,
                }
            ];

            this.dataRemainingGood = filteredData.map((obj, i) => {
                return { ...obj, id: String(i+1) };
            });

            this.statusBarReportTable = {
                statusPanels: [
                    { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                    { statusPanel: 'agTotalRowCountComponent', align: 'center' },
                    { statusPanel: 'agFilteredRowCountComponent' },
                    { statusPanel: 'agSelectedRowCountComponent' },
                    {
                        statusPanel: 'agAggregationComponent',
                        statusPanelParams: { aggFuncs: ['count', 'sum', 'min', 'max', 'avg'] },
                    }
                ],
            };

        },
        async setDataForIndicatorReportCost(data, uniqueNamesItem) {
            this.statusBarReportTable = {};

            let filteredData = data;
            if(uniqueNamesItem && uniqueNamesItem.length > 0){
                filteredData = data.filter(item => uniqueNamesItem.includes(item.name));
            }

            this.columsRemainingGood = [
                {
                    field: 'id',
                    headerName: '№',
                    width:50,
                    hide:true
                },
                {
                    field: 'date',
                    headerName: 'Date',
                    width:100,
                },
                {
                    field: 'nameDevice',
                    headerName: 'Name device',
                    width:120,
                },
                {
                    field: 'name',
                    headerName: 'Categories',
                    width:150,
                },
                {
                    field: 'item_name',
                    headerName: 'Item',
                    width:280,
                },
                {
                    field: 'priceBuy',
                    headerName: 'Price buy',
                    width:100,
                },
                {
                    field: 'price',
                    headerName: 'Price',
                    width:70,
                },
                {
                    field: 'quantity',
                    aggFunc: 'sum',
                    headerName: 'Quantity',
                    width:80,
                },
                {
                    field: 'summa',
                    aggFunc: 'sum',
                    headerName: 'Total',
                    width:70,
                },
                {
                    field: 'summaCost',
                    aggFunc: 'sum',
                    headerName: 'Total cost',
                    width:100,
                },
                {
                    field: 'profit',
                    aggFunc: 'sum',
                    headerName: 'Profit',
                    width:70,
                }
            ]

            const updatedData = filteredData.map(function(item) {
                let summaCost = item.quantity * item.priceBuy;
                let profit = item.summa - summaCost;
                return {
                    ...item,
                    summaCost: parseFloat(summaCost).toFixed(2),
                    profit: parseFloat(profit).toFixed(2),
                };
            });

            this.dataRemainingGood = updatedData.map((obj, i) => {
                return { ...obj, id: String(i+1) };
            });

            this.statusBarReportTable = {
                statusPanels: [
                    { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                    { statusPanel: 'agTotalRowCountComponent', align: 'center' },
                    { statusPanel: 'agFilteredRowCountComponent' },
                    { statusPanel: 'agSelectedRowCountComponent' },
                    {
                        statusPanel: 'agAggregationComponent',
                        statusPanelParams: { aggFuncs: ['count', 'sum', 'min', 'max', 'avg'] },
                    }
                ],
            };

        },
        async setSettingDashboard(payload) {
            const {device, item} = payload;
            this.selectedDevice = device;
            this.selectedItem = item;

            this.settingDashboard.conveniq = device;
            this.settingDashboard.category = item;

        },
        async resetDate(countDay = 7) {
            const endDate = new Date();
            endDate.setHours(23, 59, 59, 999);

            const startDate = new Date(new Date().setDate(endDate.getDate() - countDay));
            startDate.setHours(0, 0, 0, 0);
            this.datePicker = [startDate, endDate];
        },
        async setDate(startDataSelected,endDataSelected) {
            const startDate = new Date(startDataSelected);
            startDate.setHours(0, 0, 0, 0);

            const endDate = new Date(endDataSelected);
            endDate.setHours(23, 59, 59, 999);

            this.datePicker = [startDate, endDate];
        },
        async postReplanishment(entity, params) {
            let res = await EntityApi.postReplanishment(entity, params);
            if (res) {
                this.dataReplanishment = res;

                this.dataWriteOff = res.filter((item) => {
                    return item.documentType === 'inventory_adjustment';// && ['defect','item_expired','tasting'].includes(item.type);
                });
            } else return null;
        },
        async setDataForWriteOffReport(data, uniqueNamesItem) {
            let filteredData = data;
            if(uniqueNamesItem && uniqueNamesItem.lenth > 0){
                filteredData = data.filter(item => uniqueNamesItem.includes(item.name));
            }

            this.statusBarReportTable = {};
            this.columsRemainingGood = [
                {
                    field: 'id',
                    headerName: '№',
                    width:50,
                    hide:true
                },
                {
                    field: 'date',
                    headerName: 'Date',
                    width:100,
                },
                {
                    field: 'nameDevice',
                    headerName: 'Name device',
                    width:120,
                },
                {
                    field: 'name',
                    headerName: 'Categories',
                    width:150,
                },
                {
                    field: 'item_name',
                    headerName: 'Item',
                    width:300,
                },
                {
                    field: 'price',
                    headerName: 'Price',
                    width:100,
                    /*valueFormatter: '$'*/
                },
                {
                    field: 'quantity',
                    aggFunc: 'sum',
                    headerName: 'Quantity',
                    width:100,
                },
                {
                    field: 'summa',
                    aggFunc: 'sum',
                    headerName: 'Suma',
                    width:100,
                }
            ];

            this.dataRemainingGood = filteredData.map((obj, i) => {
                return { ...obj, id: String(i+1) };
            });

            this.statusBarReportTable = {
                statusPanels: [
                    { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                    { statusPanel: 'agTotalRowCountComponent', align: 'center' },
                    { statusPanel: 'agFilteredRowCountComponent' },
                    { statusPanel: 'agSelectedRowCountComponent' },
                    {
                        statusPanel: 'agAggregationComponent',
                        statusPanelParams: { aggFuncs: ['count', 'sum', 'min', 'max', 'avg'] },
                    }
                ],
            };

        },

    },
});
