<template>
  <div class="v-row">
    <div class="v-col-md-12 v-col-12">
      <div v-if="rowData.length == 0" class="d-flex h-100 w-100 flex-column align-items-center justify-content-center" style="min-height: 20vh;">
        <h2 v-if="loading > 0">{{ $t('lables.loading') + '...' }}</h2>
        <h2 v-else>{{$t('lables.noData')}}</h2>
      </div>

      <base-card v-if="rowData.length > 0">
        <template v-slot:header>
          <div class="d-flex justify-content-between w-100">
            <h2>{{ entityFields?.title }}</h2>
            <base-button
               v-if="cardType !== 'show'"
              :variant="'pure'"
              @click="addEntityInstance"
              className="v-btn v-btn--flat v-theme--BLUE_THEME bg-primary v-btn--density-default v-btn--size-default v-btn--variant-elevated ml-auto"
              >{{ $t("buttons.addNew") }}
              <span class="ml-1"
                ><b>{{ entityFields?.title }} </b></span
              >
            </base-button>
          </div>
        </template>

        <base-table
          v-if="rowData.length > 0"
          :key="tableKey"
          :entity="entity"
          :subtableSchema="subtableSchema"
          :row="rowData"
          :columnDefs="column"
          :settings="settings"
          :cardType="cardType"
          :countRowsLoaded="countRowsLoaded"
          :loadNextPage="loadNextPage"
          :paramsRequest="paramsRequest"
          :entityPermission="entityPermission"
          tableType="subTable"
          @subtableEditRowBtnClick="onSubtableEditRowClick"
          @subtableShowRowBtnClick="onSubtableShowRowBtnClick"
          @subtableDeleteRowBtnClick="onSubtableDeleteRowBtnClick"
          ref="subTable"
        ></base-table>

      </base-card>
    </div>
  </div>
  <base-modal v-model="showInstanceDetail" :showOwerlay="true">
    <instance-card
      :entity="entityFields.model"
      :item="instanceObject"
      :schema="entityFields"
      :cardType="instanceCardType"
      :defaultValues="instanceDefaultValues"
      @inctanse-data-update="onInctanseDataUpdate"
      @inctanse-create="onInctanseCreate"
      @close-modal="showInstanceDetail = false"
    >
    </instance-card>
  </base-modal>
</template>
<script>
import BaseTable from "@/components/BaseTable.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseModal from "@/components/BaseModal.vue";
import EntityApi from "@/api/entityApi";
import { hasProperty } from "@/helpers";
import { computed } from "vue";

export default {
  name: "InstanceCardTable",
  props: {
    tableEntity: {
      type: Object,
      required: true,
    },
    subtableSchema: {
      type: Object,
      required: true,
    },
    parrentEntityId: {
      type: String,
      required: true,
    },
    cardType: {
      type: String,
      default: "edit",
    },

  },
  inject: [],
  components: { BaseTable, BaseCard, BaseButton, BaseModal },
  data() {
    return {
      entityFields: null,
      instanceObject: null,
      instanceCardType: "edit",
      column: null,
      rowData: [],
      settings: {},
      showInstanceDetail: false,
      refreshSubtableRow: null,
      loadNextPage: true,
      entity: '',
      paramsRequest: null,
      countRowsLoaded:50,
      startRowsLoaded:100,
      tableKey:1,

      loading: 0,

      arrayNames: ['date', 'name','conveniq'],
      width_spec: 140,
    };
  },
  watch: {
    tableEntity(){
      this.ititInstanceTable();
    }
  },
  computed: {
    instanceDefaultValues(){
      return {
        [this.subtableSchema?.relationship_field]:this.parrentEntityId
      }
    },
    entityPermission(){
      if (this.entityFields?.permissions){
        return this.entityFields.permissions
      }
      else return null
    }
  },
  methods: {  
    ititInstanceTable() {
      this.column = null;
      this.entity = this.subtableSchema?.model;
      this.getEntityFields(this.entity);
      this.getEntityItems(this.entity);
    },
    addEntityInstance() {
      console.log("addEntityInstance");
      this.instanceCardType = "create";
      this.instanceObject = {};
      this.showInstanceDetail = true;
    },
    onInctanseCreate(data) {
      this.rowData?.push(data);
      this.$refs.subTable.addNewRow(data);
      this.instanceObject = {};
      this.showInstanceDetail = false;
    },
    onSubtableShowRowBtnClick(row) {
      console.log("onShowRowBtnClick !!!!", row);
      this.instanceCardType = "show";
      this.instanceObject = row;
      this.showInstanceDetail = true;
    },
    onSubtableEditRowClick(row) {
      console.log("onSubtableEditRowClick", row);
      this.instanceCardType = "edit";
      this.instanceObject = row;
      this.showInstanceDetail = true;
    },
    onSubtableDeleteRowBtnClick(row) {  
      // console.log(this.$route?.params?.entitys, this.parrentEntityId, row.id);
      this.rowData = this.rowData.filter((el) => el.id !== row.id);
      this.$refs.subTable.refreshTableData();      
      if(this.entityFields?.custom_endpoints && hasProperty(this.entityFields.custom_endpoints, 'delete') ){
          let customDeleteEndpoint = this.entityFields.custom_endpoints.delete;
          EntityApi.sendCustomRequest(customDeleteEndpoint?.url, customDeleteEndpoint?.method, row);
        }
        else {
          EntityApi.deleteEntityInstance(this.subtableSchema?.model, row?.id);
        } 
    },
    onInctanseDataUpdate(data) {
      console.log("onInctanseDataUpdate");
      let obg = this.rowData?.find((el) => el.id === data.id);
      for (const key in data) {
        if (key !== "id") {
          obg[key] = data[key];
        }
      }
      this.$refs.subTable.refreshRowData(data);
      this.instanceObject = null;
      this.showInstanceDetail = false;
    },
    async getEntityFields(entity) {
      this.loading++;
      EntityApi.getEntityFields(entity).then(res => {
        this.loading--;
        this.entityFields = res;
        this.column = this.generateColumn(this.entityFields);
        this.tableKey = this.tableKey+1;
        this.separateString(this.entityFields)
      });
    },
    async getEntityItems(entity) {
      let params = {
       [`${this.subtableSchema?.relationship_field}__eq`]
        : this.parrentEntityId}

      this.loading++;
      //this.rowData = await EntityApi.getEntityItems(entity, params);
      EntityApi.getEntityItemsLimit(entity, 0, this.startRowsLoaded, params, false).then(res => {
        this.loading--;
        this.rowData = res
        this.entity = entity;
        this.paramsRequest = params;
      });
    },
    isFileField(field) {
      return this.entityFields?.file_fields.includes(field) ? true : false;
    },
    dtFormat(dt){

      function convertDateForIos(date) {
        if(typeof date == 'string'){
          let arr = date.split(/[- :]/);
          let dt2 = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
          return dt2;
        } else {
          return date
        }
      }

      if((dt != '') && (dt != null)){
        let d = null;
        if( typeof dt == 'string'){

          if(dt.indexOf('T') > -1){
            let arr = (dt).split('T');
            let dataT = arr[0] + ' ' + arr[1];
            d = convertDateForIos(dataT);
          } else {
            d = convertDateForIos(dt);
          }

          let dtn = +convertDateForIos(d)
          if( dtn < 0){
            return null
          }
        } else if(typeof dt == 'number'){
          d = new Date(dt);
        }else {
          d = new Date(dt);
        }

        let obj = {
          year: d.getFullYear(),
          month: d.getMonth()+1,
          day: d.getDate(),
          hours: d.getHours(),
          min: d.getMinutes(),
          sec: d.getSeconds(),
          int: new Date(d).getTime()
        }
        
        return obj;
      } else {
        return dt;
      }

    },
    numberComparator: (valueA, valueB) => {
      if (valueA == valueB) return 0;
        return (valueA > valueB) ? 1 : -1;
    },
    dateComparator: (valueA, valueB) => {
      if (!valueA && !valueB) {         
        return 0;
      }
      if (!valueA) {
        return -1;
      }
      if (!valueB) {
        return 1;
      }         
      let strToUnix = (str) =>{
        const [dateRelated, timeRelated] = str.split(' ');          
        const [day, month, year] = dateRelated.split('.');         
        const [hours, minutes, seconds] = timeRelated.split(':');
        return new Date(+year, month - 1, +day, +hours, +minutes, +seconds).getTime(); 
      } 
      let cellValueA = strToUnix(valueA);
      let cellValueB = strToUnix(valueB);        
      if (cellValueA === null && cellValueB === null) {
        return 0;
      }
      if (cellValueA === null) {
        return -1;
      }
      if (cellValueB === null) {
        return 1;
      }       
      return cellValueA - cellValueB;
    },
    generateColumnItem(itemObg, item) {
      //console.log('generateColumnItem',itemObg, item);
      let newItem = {
        field: item,
      };
      if(this.arrayNames.includes(item)){
        newItem.minWidth = this.width_spec;
      }
      if (hasProperty(itemObg, "title")) {
        newItem.headerName = itemObg.title;
      }
      if (hasProperty(itemObg, "$ref")) {
        newItem.cellRenderer = "objectRenderer";
        newItem.valueGetter  = `data.${item}.name`; 
        // newItem.keyCreator = params => params.value.name;
      }
      if (this.isFileField(item)) {
        newItem.cellRenderer = "fileRenderer";
      }
      if (hasProperty(itemObg, "format")) {
        switch (itemObg.format) {
          case "date-time":
            newItem.cellRenderer = "dateRenderer";
            newItem.editable = false;
            newItem.comparator = this.dateComparator;
            newItem.enablePivot = true;
            newItem.enableRowGroup = true;
            newItem.filter = "agDateColumnFilter";
            newItem.pivot = false;
            newItem.resizable = true;
            newItem.rowGroup = false;
            newItem.sortable = true;
            newItem.filterParams = {
              browserDatePicker: true,
              buttons: ['reset'],
              comparator:  (filterLocalDateAtMidnight, cellValue) => {
                
                if (cellValue == null) return -1;
                let cellDate = this.dtFormat(cellValue);
                let filterVal = this.dtFormat(filterLocalDateAtMidnight);

                if((cellDate.day == filterVal.day) && (cellDate.month == filterVal.month) && (cellDate.year == filterVal.year)) {
                  return 0;
                }
                if (cellDate.int <= filterVal.int) {
                  return -1;
                }
                if (cellDate.int >= filterVal.int) {
                  return 1;
                }
              },
            }
            break;
          default:
            break;
        }
        console.log(itemObg.format);
      }
      if (hasProperty(itemObg, "type")) {
        switch (itemObg.type) {
          case "boolean":
            newItem.cellRenderer = "boolRenderer";
            break;
          case "number":
            newItem.sortable = true;
            newItem.filter = "agNumberColumnFilter";
            newItem.pivot = false;
            newItem.enablePivot = true;
            newItem.enableRowGroup = true;
            newItem.rowGroup = false;
            newItem.resizable = true;
            newItem.editable = false;
            newItem.comparator = this.numberComparator;
            break;
          default:
            break;
        }
      }
      return newItem;
    },
    generateColumn(schema) {
      let column = [];
      let actionColl = {
        cellRenderer: "rowMenuCellRenderer",
        checkboxSelection: false,
        headerCheckboxSelection: false,
        headerName: "",
        /*maxWidth: 65,*/
        minWidth: 65,
        /*width: 65,*/
        pinned: false,
        sortable: false,
        suppressMenu: false,
      };
      column.push(actionColl);
      if (schema?.list_columns && Array.isArray(schema.list_columns)) {
        schema.list_columns.forEach((el) => {
          if (el !== "id") {
            let columnItem = this.generateColumnItem(
              schema?.properties[el],
              el
            );
            if (columnItem) {
              column.push(columnItem);
            }
          }
        });
      }
      return column;
    },
    separateString(value) {
      let words;
      if(this.entityFields.title){
        words = this.entityFields.title.match(/([A-Z][A-Z]*(?=[A-Z][a-z])|[A-Z][a-z]+)/g);
      }
      if (words) {
        const firstWord = words[0];
        const secondWord = words[1];
        this.entityFields.title = firstWord + " " + secondWord;
      }
      return value;
    }
  },
  provide() {
    return {
      refreshSubtableRow: computed(() => this.refreshSubtableRow),
    };
  },
  created() {
    this.ititInstanceTable();
  },
  mounted() {
    // console.log(this.$route?.params);
  },
};
</script>
<style scoped>
</style>
