<template>
  <header
    class="v-toolbar v-toolbar--density-default bg-background elevation-0 v-theme--BLUE_THEME v-app-bar horizontal-header"
    style="
      top: 0px;
      z-index: 2;
      transform: translateY(0%);
      position: fixed;
      left: 0px;
      width: calc((100% - 0px) - 0px);
      overflow: inherit;
    "
  >
    <div class="v-toolbar__content">
      <div class="v-toolbar__content justify-content-between px-4">
        <div class="d-flex align-center">
          <div v-if="!isMobile"
               class="align-center mobile-menu">
            <div class="logo mobile-menu">
              <a href="/" @click.prevent="goHome">
                <img
                  src="@/assets/img/logo-dark.svg"
                  alt="Logo"
                  style="width: 190px"
                  class="mobile-menu"
                />
              </a>
            </div>
          </div>
          <base-button
            v-if="!isMobile"
            :variant="'pure'"
            className="v-btn v-btn--icon v-theme--BLUE_THEME text-primary v-btn--density-default v-btn--size-small v-btn--variant-text custom-hover-primary ml-2"
            aria-haspopup="menu"
            aria-expanded="false"
            aria-owns="v-menu-669"
          >
            <svg-icon
              class="icon icon-tabler icon-tabler-search"
              type="mdi"
              :path="searchIcon"
              :size="24"
            ></svg-icon>
          </base-button>
          <header-menu @showMobileMenu="showMobileMenu"></header-menu>
          <div v-if="isMobile"
               class="align-center">
            <div class="logo">
              <a href="/" @click.prevent="goHome">
                <img
                    src="@/assets/img/logo-dark.svg"
                    alt="Logo"
                    style="width: 190px"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="d-flex align-center">
          <!-- <span class="mdi mdi-briefcase"></span> -->          
          <language-switcher></language-switcher>
          <header-user-menu></header-user-menu>
        </div>
      </div>
    </div>
  </header>
  <header-mobile-menu
      class="burger-menu mobile-menu"
      v-if="visibleMobileMenu"
      @click-outside="closeMobileMenu"></header-mobile-menu>
</template>
<script>
import BaseButton from "@/components/BaseButton.vue";

import HeaderMenu from "@/components/header/HeaderMenu.vue";
import HeaderMobileMenu from "./HeaderMobileMenu.vue";
import LanguageSwitcher from "./LanguageSwitcher.vue";
import HeaderUserMenu from "./HeaderUserMenu.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiMagnify } from "@mdi/js";

export default {
  name: "PageHeader",
  props: {},
  components: {
    BaseButton,
    HeaderMenu,
    SvgIcon,
    LanguageSwitcher,
    HeaderUserMenu,
    HeaderMobileMenu
  },
  data() {
    return {
      searchIcon: mdiMagnify,
      isMobile: false,
      visibleMobileMenu: false
    };
  },
  methods: {
    goHome(){
        this.$router.push({name:"Home"})
    },
    showMobileMenu() {
      this.visibleMobileMenu = true;
    },
    closeMobileMenu() {
      if (this.visibleMobileMenu) {
        this.visibleMobileMenu = false;
      } else {
        this.visibleMobileMenu = true;
      }
    }
  },
  created(){
    
  },
  mounted() {
    this.isMobile = window.innerWidth <= 980;
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth <= 980;
    });
  },
};
</script>
<style>
 .logo{
   user-select: none;
 }
</style>
