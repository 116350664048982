<template>
  <div class="d-flex w-100 flex-column justify-space-between" >
    <div class="d-flex w-100" style="position: relative;" @click="emitPhoto">
      <div style="position: absolute; top: 0px; left: 0px; width: 100%;" v-if="imageMarked && showMarked">
        <img :src="imageMarked" alt="no marked file" style="max-width: 100%; max-height: 100%;min-width: 100%;min-height: 100%;" />
      </div>
      <img :src="imageShlf" alt="no file" v-if="showFoto" style="max-width: 100%; max-height: 100%; pointer-events: none; height: fit-content;" />
      <!-- style="max-width: 100%; max-height: 100%;min-width: 100%;min-height: 100%;" -->
    </div>

    <div class="d-flex flex-column">
      <div class="d-flex flex-row w-100 justify-content-center">
        <h5>{{title}}</h5>
      </div>
      
      <base-table
        v-if="showtable"
        :row="dataTable"
        :columnDefs="columnDefs"
        :rowHeight="30"
        :settings="globalSettings"
      >
      </base-table>
      <h3 class="d-flex justify-content-center" v-else>{{$t('receipt_analyz.label.no_data_table')}}</h3>

    </div>
  </div>
</template>
<script>
import BaseTable from "@/components/BaseTable";

export default {
  name: "item-analyz",
  props: {
    data: {
      type: Object,
      required: true,
    },
    title: {type: String, required: false, default: ''},
    showMarked: {type: Boolean, required: false, default: false},
  },
  emit: ['zoomPhoto'],
  inject: [],
  components: {
    BaseTable
  },
  data() {
    return {
      showFoto: true,

      dataTable: [],

      showtable: false,

      globalSettings: {
        sideBar: false,
        headerHeight: 0,
        height: 400,
        groupIncludeTotalFooter: false,
        groupIncludeFooter: false,
        showGridControll: false,
        defaultColDef: {
          flex: 1,
          sortable: false,
          resizable: true,
          minWidth: 100
        },
        domLayout: 'autoHeight',
      },
    };
  },
  watch: {},
  computed: {
    imageShlf(){
      if(this.data && this.data.file_url){
        return this.data.file_url
      } else {
        return null
      }
    },
    imageMarked(){
      if(this.data && this.data.image_annotation){
        return 'data:image/png;base64,' + this.data.image_annotation
      } else {
        return null
      }
    },
    columnDefs(){
      /*let columns = Array.from(
        new Set(this.dataRow.flatMap((obj) => Object.keys(obj)))
      );
      
      columns = columns.map((column) => ({field: column}));

      let indx = columns.findIndex(el => {return el.field == 'item_id'});
      if(indx > -1) { columns[indx].hide = true; }*/
      let columns = [
        {
          field: 'item_id',
          hide: true
        },
        {
          field: 'item_name',
          cellStyle: params => params.data.attention ? { backgroundColor: 'rgba(255, 0, 0, 0.479)' } : { backgroundColor: '' },
        },
        {
          field: 'titleVal',
          /*cellClassRules: {
            'red-back': (params) => {
              if(params.data.attention){ console.log(params); return true } else return false
            }
          },*/
          width: 30,
          cellStyle: params => params.data.attention ? { backgroundColor: 'rgba(255, 0, 0, 0.479)' } : { backgroundColor: '' },
        }
      ];


      return columns;
    },
  },
  methods: {
    emitPhoto(){
      this.$emit('zoomPhoto', null);
    },
    prepareData(){

      if(this.data && this.data.processed_data && (this.data.processed_data.length > 0)){
        this.dataTable = this.data.processed_data;
        //this.$nextTick(()=>{ this.showtable = true; });
        setTimeout(()=>{ this.showtable = true; },800);
      } else if(this.data && this.data.remains && (this.data.remains.length > 0)){
        this.dataTable = this.data.remains;
        //this.$nextTick(()=>{ this.showtable = true; });
        setTimeout(()=>{ this.showtable = true; },800);
      } else {
        this.showtable = false;
      }
    },
  },
  created() {},
  mounted() {
      this.prepareData();
  },
};
</script>

<style scoped>
.w-50{
  width: 50%;
}

.ag-horizontal-left-spacer {
  visibility: hidden;
}

.ag-body-horizontal-scroll{
  display: none !important;
}
</style>
