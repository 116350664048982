<template>
  <li class="navItem">
    <a
      class="navItemLink rounded-md cursor-pointer"
      :href="item.href"
      @click.prevent="goToUrl(item)"
    >
      <i class="navIcon" v-if="item.icon">
        <Icon :icon="item.icon" class="icon icon-tabler" height="18" />
      </i>
      <span class="mr-auto">{{ item.label }}</span>
      <i class="ddIcon ml-1 d-flex align-center" v-if="hasSubmenu(item)"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-chevron-down"
          width="15px"
          height="15px"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <polyline points="6 9 12 15 18 9"></polyline>
        </svg>
      </i>
    </a>
    <ul class="ddMenu ddLevel-1" v-if="hasSubmenu(item)">
      <header-menu-item
        v-for="subItem in item.items"
        :key="subItem.id"
        :item="subItem"
      ></header-menu-item>
    </ul>
  </li>
   <base-dialog 
    v-model="showDialog"  
    contentWidth='500px'
    :showCloseBtn='true'
    :showOwerlay="true"
    :type="dialogType"
    >
    <h2 v-if="status === 'ok'">{{$t("lables.success")}}</h2>
    <h2 v-else>{{$t("lables.error")}}</h2>
    <div class="">
      {{requestResalt}}
    </div> 
  </base-dialog>
</template>
<script>
import BaseDialog from "@/components/BaseDialog.vue";

import { Icon } from "@iconify/vue";
import { sendRequest } from "@/helpers";
export default {
  name: "HeaderMenuItem",
  props: {
    item: {
      type: Object,
    },
  },
  components: { Icon, BaseDialog },
  data() {
    return {
      showDialog:false,
      dialogType:null,
      requestResalt:null,
      status:null
    };
  },
  methods: {
    hasSubmenu(item) {
      return Object.prototype.hasOwnProperty.call(item, "items") ? true : false;
    },
    goToUrl(item) {
      let itemId = item?.id;
      switch (item.type) {
        case "entity_list":
          this.$router.push({ path: item?.href, replace: true });
          break;
        case "internal_link":
          this.$router.push({ path: item?.href, replace: true });
          break;
        case "external_link":
          console.log("open link in iframe");
          this.$router.push({ name: "external",  params: { id: itemId }, replace: true });
          break;
        case "api_method":
          console.log("send api request");
          this.sendRequest(item);
          break;
        case "redirect":
          console.log("redirect");
          if(item && item.href){
            window.open(item.href);
          }
          break;
        default:
          console.log(item.type);
          break;
      }
      
    },
    async sendRequest(item){
      let res = await sendRequest(item.href, 'get');
      if (res){
        this.requestResalt = "Ok";
        this.status = 'ok';
        this.dialogType = "success"
        this.showDialog = true;
      }
      else {
        this.requestResalt = "Error";
        this.dialogType = "error"
        this.status = 'not ok';
        this.showDialog = true;
      }
    }
  },
  mounted() {},
};
</script>
